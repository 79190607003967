export const environment = {
  production: true,
  NX_PUBLIC_VERSION: `1.0.${process.env.NX_PUBLIC_VERSION ?? 0}`,
  NX_PUBLIC_BRANCH: process.env.NX_PUBLIC_BRANCH,
  NX_DICOM_MAX_FILE_SIZE_BYTES: '3221225472',
  NX_BUILD_NUMBER: process.env.NX_PUBLIC_VERSION ?? 0,

  NX_SERVER_GQL: 'https://api.staging.myaprevo.com/graphql',
  NX_AUTH_REDIRECT_URI: 'https://staging.myaprevo.com/code',
  NX_CYBORG_ENDPOINT: 'https://cyborg.staging.myaprevo.com',
  NX_CLIENT_URL: 'https://staging.myaprevo.com',
  NX_CLIENT_ID: '45a6a608-45d8-48c9-bee0-5b3f7916a782',

  NX_AUTH_METHOD: 'OAUTH',
  NX_AUTH_URL: 'https://workflow-jumpcloud.auth.us-west-2.amazoncognito.com/login',
  NX_OAUTH_URL: 'https://workflow-jumpcloud.auth.us-west-2.amazoncognito.com',
  NX_OAUTH_IDP_JUMPCLOUD: 'JumpcloudIdP',
  // NX_OAUTH_IDP_GOOGLE: 'Google',
  NX_AUTH_RESPONSE_TYPE: 'token',
  NX_AUTH_SCOPE: 'email+openid+phone+profile',
  NX_AUTH_CLIENT_ID: '6a90qpgj3ok5dubab7fop4g916',

  NX_ONESIGNAL_APP_ID: 'd854af8c-9f47-4c71-8c7c-49f9cb6364a1',
  NX_ONESIGNAL_ENABLED: true,
  NX_MIXPANEL_TOKEN: 'e428cc5460e73e74b430b1780331ff7e',

  NX_FF_ALIFX_ORIENTATION: true,
  NX_FF_TLIFC_ORIENTATION: true,
  NX_FF_AUTO_CORRECT: false,
  NX_FF_DASHBOARD_PRODUCTION_CALENDAR: false,
  NX_FF_M4_INSERTER_INVENTORY_MANAGEMENT: true,
  NX_FF_M4L_EXPANDED_INVENTORY_AVAILABLE: true,
  NX_FF_DESIGN_REQUIRE_TEM_013: true,
  NX_FF_TEM_013_AUTOMATION: false,
  NX_FF_MAGIC_LINKS: false,

  NX_ENVIRONMENT: 'staging',
};
