import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  AssetType,
  CaseStageType,
  caseUtils,
  ICase,
  IPlan,
  Permission,
  PlanStatusType,
  UserRoleType,
  ValidFileExtensions,
} from '@workflow-nx/common';
import * as FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import React, { Dispatch, useCallback, useReducer, useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import CustomDialog from '../../../../components/CustomDialog';
import {
  COMPLETE_CASE_STAGE,
  FIND_PLAN,
  FIND_PLANS,
  UPDATE_PLAN_IS_ACTIVE,
  UPDATE_PLAN_PLUS_LEVEL_SIZE,
  UPDATE_PLAN_VERSION,
} from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { ExportNTopAssetsDialog } from '../CaseDesignTab/ExportNTopAssetsDialog';
import { PlanningRejectionDialog } from '../PlanningRejectionDialog';
import { CasePlanningTabReducer, CasePlanningTabStateType } from './CasePlanningTab.reducer';
import { PlanDetailsView } from './PlanDetailsView';
import { PlanDescriptionView } from './PlanDescriptionView';
import { grey } from '@mui/material/colors';
import { PlanHeaderView } from './PlanHeaderView';
import config from '../../../../extras/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserRobot as faUserRobotSolid } from '@fortawesome/pro-solid-svg-icons';
import { faUserRobotXmarks } from '@fortawesome/pro-light-svg-icons';
import { AutoCorrectView } from './AutoCorrectionDialog/AutoCorrectView';
import { FeatureFlag } from '../../../../utils/featureFlags';
import { sortBy } from 'lodash';
import { PatientPelvisSettingsView } from './PatientPelvisSettingsView';
import { StageErrorsView } from './StageErrorsView';
import { PreOpStandingXrayAssetGridTable } from 'apps/workflow-client/src/app/components/PreOpStandingXrayAssetGridTable';
import { useConfirm } from 'material-ui-confirm';
import { ImplantSizeExclusionAlert } from '../../../../components/ImplantSizeExclusionAlert';

export function CasePlanningTabView(props: { activeCase: ICase; dispatch: Dispatch<any> }) {
  const { hasFeatureFlag, hasRole, hasPermission, externalApps } = useAuth();
  const confirm = useConfirm();

  const [completeCaseStage, { loading: loadingCompleteCase }] = useMutation(COMPLETE_CASE_STAGE);
  const [openPlanningRejectionDialog, setOpenPlanningRejectionDialog] = useState(false);
  const [upsertPlanVersion] = useMutation(UPDATE_PLAN_VERSION);
  const [updatePlanIsActive] = useMutation(UPDATE_PLAN_IS_ACTIVE);
  const [upsertPlanPlusLevelSize] = useMutation(UPDATE_PLAN_PLUS_LEVEL_SIZE);
  const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [findPlan, { loading: loadingFindPlan }] = useLazyQuery(FIND_PLAN, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      planningTabDispatch({
        type: 'SELECT_PLAN',
        data: data.plan,
      });
      setSelectedPlan(data.plan);
    },
  });

  const { loading: loadingFindPlans } = useQuery(FIND_PLANS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
    },
    onCompleted: (data) => {
      const plans = data.plans as IPlan[];

      let foundPlan = plans.find((plan) => plan.status === PlanStatusType.Approved);
      if (!foundPlan) {
        foundPlan = plans.find((plan) => plan.status === PlanStatusType.Proposed);
        if (!foundPlan) {
          foundPlan = plans.find((plan) => plan.isActive);
          if (!foundPlan) {
            foundPlan = sortBy(plans, ['updatedAt'])[0];
          }
        }
      }

      planningTabDispatch({
        type: 'SELECT_PLAN',
        data: foundPlan,
      });
      setSelectedPlan(foundPlan);
    },
  });

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [showPlanAssetsDialog, setShowPlanAssetsDialog] = useState(false);

  const requiredPlanAssets = [
    ...caseUtils.getCaseVertebralBodyAssets(props.activeCase.spineProfile).standard,
  ];

  const requiredImplantImageAssets = caseUtils.getCaseImplantMeasurementImageAssets(
    props.activeCase.levels,
  );

  const initialState: CasePlanningTabStateType = {
    caseLevels: [],
    errors: [],
    patientRecord: props.activeCase.patient.patientRecord,
    plan: undefined,
    planAssets: undefined,
    canMoveToProposed: false,
    isEditingAllowed: false,
    isStageComplete: false,
    interbodyDataAssets: undefined,
    requiredAppAssets: undefined,
  };

  const editingAllowed = !!hasPermission?.([
    Permission.ManageCase,
    Permission.EditCase,
    Permission.ManagePlanApproval,
  ]);

  const [state, planningTabDispatch] = useReducer(
    CasePlanningTabReducer(props.activeCase, editingAllowed),
    initialState,
  );

  const handleUpdatePlanIsActive = async (plan: IPlan) => {
    try {
      await updatePlanIsActive({
        variables: {
          caseId: props.activeCase.caseId,
          planId: plan.planId,
        },
      });

      enqueueSnackbar(`Plan ${plan.name} has been set to the active plan`, {
        variant: 'success',
      });

      props.dispatch({ type: 'refetch' });
    } catch (e) {
      console.error(`Error setting plan ${plan.name} to active`, e);
      enqueueSnackbar(`An error occurred setting the plan ${plan.name} to active`, {
        variant: 'error',
      });
    }
  };

  const handlePlanVersionClicked = async () => {
    if (!state.plan) return;

    const version = state.plan.version === 1 ? 2 : 1;

    try {
      await upsertPlanVersion({
        variables: {
          caseId: state.plan?.caseId,
          planId: state.plan?.planId,
          version: version,
        },
      });

      await findPlan({ variables: { planId: selectedPlan?.planId } });

      enqueueSnackbar(`Plan has been changed to version ${version}.`, {
        variant: 'success',
      });
    } catch (e) {
      console.error('Error updating the plus level size', e);
      enqueueSnackbar(`An error occurred changing the plan to version ${version}.`, {
        variant: 'error',
      });
    }
  };

  const handlePlusLevelSizeClicked = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const plusLevelSize = event.target.checked ? 1 : 2;

    try {
      await upsertPlanPlusLevelSize({
        variables: {
          caseId: state?.plan?.caseId,
          planId: state?.plan?.planId,
          plusLevelSize: plusLevelSize,
        },
      });

      planningTabDispatch({
        type: 'UPDATE_PLUS_SIZE',
        data: plusLevelSize,
      });

      enqueueSnackbar(`Plan has been changed to plus level size ${plusLevelSize}mm.`, {
        variant: 'success',
      });
    } catch (e) {
      console.error('Error updating the plus level size');
      enqueueSnackbar(`An error occurred changing the plus level size to ${plusLevelSize}mm.`, {
        variant: 'error',
      });
    }
  };

  const handleCompleteCaseStage = useCallback(async () => {
    try {
      await completeCaseStage({
        variables: {
          caseId: props.activeCase.caseId,
        },
      });

      props.dispatch({ type: 'refetch' });

      enqueueSnackbar('Case updated successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error updating case', {
        variant: 'error',
      });
    }
  }, [completeCaseStage, props, enqueueSnackbar]);

  return (
    <>
      <Card>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            mt: 2,
            mx: 2,
          }}
          alignItems={'center'}
        >
          <Typography variant={'h2'}>Case Planning</Typography>
          <Box flexGrow={1} />
          {hasFeatureFlag?.(FeatureFlag.cyborgV2Enabled) && externalApps?.cyborgUrl ? (
            <IconButton
              size={'large'}
              onClick={() => {
                window.open(
                  `${externalApps?.cyborgUrl}/cases/${props.activeCase.caseId}`,
                  'cyborg',
                );
              }}
            >
              <Badge badgeContent={'V2'} color="secondary">
                <FontAwesomeIcon icon={faUserRobotSolid} size={'lg'} />
              </Badge>
            </IconButton>
          ) : null}
          <IconButton
            size={'large'}
            onClick={async () => {
              try {
                if (hasFeatureFlag?.(FeatureFlag.cyborgV2Enabled)) {
                  await confirm({
                    allowClose: false,
                    title: `Open Cyborg V1?`,
                    description: (
                      <>
                        <p>
                          Cyborg version 1 is deprecated and has been replaced by Cyborg version 2.
                          Please use it instead.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          If there is an issue with Cyborg version 2 preventing you from designing a
                          surgical plan, please let your manager and the Enabling Technologies
                          department know.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          Press <strong>OK</strong> to open Cyborg V1.
                        </p>
                      </>
                    ),
                  });
                }

                window.open(
                  `${config.cyborg.endpoint}/app/integrated/${props.activeCase.number}`,
                  'cyborg',
                );
              } catch (e) {}
            }}
          >
            <Badge badgeContent={'V1'} color="secondary">
              <FontAwesomeIcon icon={faUserRobotXmarks} size={'lg'} />
            </Badge>
          </IconButton>
          {hasRole?.([UserRoleType.CaseAdmin, UserRoleType.SiteAdministrator]) &&
          config.featureFlags.autoCorrect ? (
            <AutoCorrectView
              activeCase={props.activeCase}
              onChange={() => {
                console.log('AutoCorrectView onChange');
              }}
            />
          ) : null}
        </Stack>
        <CardContent>
          {state.isStageComplete ? (
            <Box mb={2}>
              <Alert severity={'success'}>This stage is complete</Alert>
            </Box>
          ) : null}

          <Box display={'flex'} flexDirection={'column'} mx={10}>
            {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
              <PatientPelvisSettingsView
                caseId={props.activeCase.caseId}
                patientId={props.activeCase.patient.patientId}
                onUpdate={(patientRecord) => {
                  planningTabDispatch({
                    type: 'UPDATE_PATIENT_RECORD',
                    data: patientRecord,
                  });
                }}
              />
            ) : null}

            {state.plan ? (
              <ImplantSizeExclusionAlert
                activeCase={props.activeCase}
                currentStage={CaseStageType.Planning}
                planId={state?.plan?.planId}
                onUpdate={() => {
                  props.dispatch({ type: 'refetch' });
                }}
              />
            ) : null}
            <Box
              sx={{
                display: 'flex',
                borderRadius: '5px',
                alignItems: 'center',
                mb: 2,
                p: 2,
                border: `1px solid ${grey[300]}`,
              }}
            >
              <PlanDescriptionView
                caseLevels={props.activeCase.levels}
                caseNumber={props.activeCase.number}
                caseApproach={props.activeCase.approach}
                patientId={props.activeCase.patient.patientId}
                length={caseUtils.getValidCaseLevels(props.activeCase.levels).length}
                surgeonUser={props.activeCase.surgeonUser}
                surgeryDate={props.activeCase.surgeryDate}
              />
            </Box>

            <Stack spacing={2}>
              <Typography variant={'h3'}>Standing X-Ray</Typography>
              <Paper>
                <Box p={1}>
                  <PreOpStandingXrayAssetGridTable caseId={props?.activeCase.caseId} />
                </Box>
              </Paper>
            </Stack>

            <Box my={2} />

            <PlanHeaderView
              selectedPlan={selectedPlan}
              activeCase={props.activeCase}
              onSelectPlan={async (selectedPlan) => {
                if (selectedPlan) {
                  handleUpdatePlanIsActive(selectedPlan);
                }
              }}
              onDeletePlan={async () => {
                props.dispatch({ type: 'refetch' });
              }}
            />

            {state.plan ? (
              <div>
                <PlanDetailsView
                  dispatch={planningTabDispatch}
                  patientRecord={state.patientRecord}
                  plan={state?.plan}
                  planAssets={state.planAssets ?? []}
                  caseLevels={state.caseLevels}
                  activeCase={props.activeCase}
                  disabled={
                    state.isStageComplete ||
                    !!selectedPlan?.deletedAt ||
                    loadingFindPlans ||
                    loadingFindPlan ||
                    loadingCompleteCase
                  }
                  requiredAppAssets={state.requiredAppAssets ?? []}
                  optionalAppAssets={state.optionalAppAssets ?? []}
                  implantImageAssets={requiredImplantImageAssets ?? []}
                  requiredPlanAssets={requiredPlanAssets}
                  editingAllowed={editingAllowed}
                  isSelectedPlanDeleted={!!selectedPlan?.deletedAt}
                  onShowPlanAssetsClick={() => setShowPlanAssetsDialog(true)}
                  onShowExportAssetsClick={() => setOpenExportDialog(true)}
                  onPlanVersionClick={handlePlanVersionClicked}
                  onPlusLevelSizeClick={handlePlusLevelSizeClicked}
                  onAppAssetsComplete={() =>
                    findPlan({ variables: { planId: selectedPlan?.planId } })
                  }
                  onPlanImplantsUpdated={() => {
                    props.dispatch({ type: 'refetch' });
                  }}
                />

                {state.plan ? (
                  <>
                    {showPlanAssetsDialog ? (
                      <CustomDialog
                        maxWidth={'md'}
                        fullHeight={false}
                        open={showPlanAssetsDialog}
                        title={`Viewing Plan Assets - ${props.activeCase.number}`}
                        onClose={() => {
                          setShowPlanAssetsDialog(false);
                        }}
                        positiveActionButtons={[]}
                      >
                        <Typography variant={'h4'}>Vertebral Body Assets</Typography>
                        <AssetGridTable
                          dispatch={planningTabDispatch}
                          assets={state.planAssets || []}
                          caseId={props.activeCase.caseId}
                          planId={state.plan?.planId}
                          showDownloadAll={true}
                          onZipComplete={(zipFile) =>
                            FileSaver.saveAs(
                              zipFile,
                              `${props.activeCase.number}-plan-stl-assets.zip`,
                            )
                          }
                          validAssets={requiredPlanAssets}
                          validFileExtensions={[ValidFileExtensions.STL]}
                          readOnly={state.isStageComplete}
                        />
                        <Box my={2}>
                          <Divider variant={'middle'} />
                        </Box>
                        <Typography variant={'h4'}>PDF assets</Typography>
                        <AssetGridTable
                          dispatch={planningTabDispatch}
                          assets={state.planAssets || []}
                          caseId={props.activeCase.caseId}
                          planId={state.plan?.planId}
                          validFileExtensions={[ValidFileExtensions.PDF]}
                          validAssets={[AssetType.PlanSummaryApp, AssetType.PlanSummaryFormApp]}
                          readOnly={state.isStageComplete}
                        />
                      </CustomDialog>
                    ) : null}
                  </>
                ) : (
                  <Alert severity={'info'}>
                    There is no proposed plan in Cyborg to display here.
                  </Alert>
                )}
              </div>
            ) : (
              <Alert severity={'warning'}>
                There is no currently select plan. Select a plan or create a new one in Cyborg.
              </Alert>
            )}
          </Box>

          {!state.isStageComplete && !selectedPlan?.deletedAt ? (
            <Box mt={4} mb={2} mx={10}>
              {!state.canMoveToProposed ? (
                <Stack gap={1}>
                  <Alert severity={'info'}>
                    All plan assets and iOS app related assets from the plan must be uploaded before
                    moving to the <strong>PROPOSED</strong> stage
                  </Alert>
                  {state.plan && state.errors.length > 0 ? (
                    <StageErrorsView errors={state.errors} />
                  ) : null}
                </Stack>
              ) : null}

              <Box display={'flex'} my={2} justifyContent={'center'}>
                <Button
                  variant={'outlined'}
                  disabled={
                    !state.isEditingAllowed ||
                    state.isStageComplete ||
                    !!props?.activeCase?.caseCancellation ||
                    loadingFindPlans ||
                    loadingFindPlan ||
                    loadingCompleteCase
                  }
                  onClick={() => setOpenPlanningRejectionDialog(true)}
                >
                  Reject Planning
                </Button>
                <Box mx={1} />
                <ActionButton
                  variant={'outlined'}
                  disabled={
                    !state.canMoveToProposed ||
                    !!props?.activeCase?.caseCancellation ||
                    loadingFindPlans ||
                    loadingFindPlan ||
                    loadingCompleteCase
                  }
                  onClick={handleCompleteCaseStage}
                  loading={loadingCompleteCase}
                >
                  Move To Proposed
                </ActionButton>
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>
      {openExportDialog ? (
        <ExportNTopAssetsDialog
          open={openExportDialog}
          activeCase={props.activeCase}
          plan={state.plan}
          onClose={() => {
            setOpenExportDialog(false);
          }}
        />
      ) : null}
      <PlanningRejectionDialog
        open={openPlanningRejectionDialog}
        caseId={props.activeCase.caseId}
        planId={state?.plan?.planId}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }
          setOpenPlanningRejectionDialog(false);
        }}
      />
    </>
  );
}
